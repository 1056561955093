<template>
  <Layout>
    <router-view />
  </Layout>
</template>

<script>
  import Layout from '@/layouts/Layout'

  export default {
    name: 'App',

    components: {
      Layout,
    },
  }
</script>

<style lang="less">
  body {
    background-color: #09091d;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
