<template>
  <!-- TODO: Add header if needed. -->
  <div class="main">
    <slot />
  </div>
  <!-- TODO: Add footer if needed. -->
</template>

<script>
  export default {
    name: 'Main Layout',

    setup() {
      return {}
    },
  }
</script>

<style scoped>
  .main {
    /* TODO: Add layout styles. */
    width: 100%;
    height: 100%;
  }
</style>
