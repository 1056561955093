import { createRouter, createWebHistory } from 'vue-router'

import { HOME_ROUTE } from '@/constants/routes'

const routes = [
  {
    path: HOME_ROUTE,
    name: 'Login',
    component: () => import('@/views/Login'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
